import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useTodosPermissions = function (user) {
    var hasPermissionListTodo = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.TODO, MethodCode.LIST);
    var hasPermissionEditTodo = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.TODO, MethodCode.EDIT);
    var hasPermissionViewTodo = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.TODO, MethodCode.VIEW);
    var hasPermissionDeleteTodo = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.TODO, MethodCode.DELETE);
    var hasPermissionCreateTodo = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.TODO, MethodCode.CREATE);
    return {
        hasPermissionListTodo: hasPermissionListTodo,
        hasPermissionEditTodo: hasPermissionEditTodo,
        hasPermissionViewTodo: hasPermissionViewTodo,
        hasPermissionDeleteTodo: hasPermissionDeleteTodo,
        hasPermissionCreateTodo: hasPermissionCreateTodo,
    };
};
export default useTodosPermissions;
